import React, { useState } from 'react';
import PageHeader from './PageHeader';

const winnersData = {
  2021: [
    { category: 'IT-специалист года', name: 'Мария Иванова', country: 'Украина', description: 'За выдающийся вклад в разработку и внедрение высоконагруженных систем для крупных корпоративных клиентов.' },
    { category: 'Лучший разработчик программного обеспечения', name: 'Игорь Тертичко', country: 'Украина', description: 'За создание инновационного программного обеспечения для автоматизации бизнес-процессов, используемого в различных отраслях.' },
    { category: 'Лидер в области кибербезопасности', name: 'Игорь Смирнов', country: 'Беларусь', description: 'За разработку и внедрение комплексных систем защиты данных, значительно улучшивших безопасность корпоративных сетей.' },
    { category: 'Лидер в области искусственного интеллекта', name: 'Екатерина Сидорова', country: 'Казахстан', description: 'За исследования и разработки в области глубокого обучения, применяемого в медицине для диагностики заболеваний.' },
    { category: 'IT-волонтер года', name: 'Олег Васильев', country: 'Узбекистан', description: 'За активное участие в благотворительных проектах по обучению молодежи основам программирования и кибербезопасности.' }
  ],
  2022: [
    { category: 'IT-специалист года', name: 'Сергей Петров', country: 'Украина', description: 'За значительный вклад в развитие финтех-решений, которые упростили доступ к финансовым услугам для широких слоев населения.' },
    { category: 'Лучший разработчик программного обеспечения', name: 'Александр Романов', country: 'Россия', description: 'За разработку инновационного ПО для анализа больших данных, применяемого в маркетинговых исследованиях.' },
    { category: 'Лидер в области кибербезопасности', name: 'Анна Белова', country: 'Казахстан', description: 'За успешное предотвращение крупных кибератак и разработку новых методов защиты корпоративных систем.' },
    { category: 'Лидер в области искусственного интеллекта', name: 'Дмитрий Павлов', country: 'Беларусь', description: 'За создание алгоритмов машинного обучения, применяемых в промышленной автоматизации.' },
    { category: 'IT-волонтер года', name: 'Виктория Мельникова', country: 'Армения', description: 'За организацию бесплатных курсов по программированию для школьников из малообеспеченных семей.' }
  ],
  2023: [
    { category: 'IT-специалист года', name: 'Елена Федорова', country: 'Беларусь', description: 'За ведущую роль в разработке и внедрении облачных сервисов для государственных учреждений, повысивших их эффективность.' },
    { category: 'Лучший разработчик программного обеспечения', name: 'Иван Григорьев', country: 'Таджикистан', description: 'За создание высокопроизводительных приложений для мобильных устройств, широко использующихся в различных сферах.' },
    { category: 'Лидер в области кибербезопасности', name: 'Мария Соколова', country: 'Грузия', description: 'За разработку передовых методов анализа угроз и реагирования на инциденты, обеспечивших защиту крупных предприятий.' },
    { category: 'Лидер в области искусственного интеллекта', name: 'Олег Тихонов', country: 'Узбекистан', description: 'За разработки в области компьютерного зрения, используемые в системах безопасности и мониторинга.' },
    { category: 'IT-волонтер года', name: 'Андрей Лебедев', country: 'Россия', description: 'За волонтерскую деятельность по созданию и поддержке образовательных платформ для школьников и студентов.' }
  ],
  2024: [
    { category: 'IT-специалист года', name: 'Виктор Зайцев', country: 'Казахстан', description: 'За создание и внедрение инновационных решений в области IoT, улучшивших производственные процессы в различных отраслях.' },
    { category: 'Лучший разработчик программного обеспечения', name: 'Ольга Кузнецова', country: 'Армения', description: 'За разработку программного обеспечения для медицинских учреждений, значительно повысившего качество и скорость обслуживания пациентов.' },
    { category: 'Лидер в области кибербезопасности', name: 'Алексей Смирнов', country: 'Узбекистан', description: 'За разработку и внедрение системы проактивной защиты от киберугроз, применяемой в крупных финансовых учреждениях.' },
    { category: 'Лидер в области искусственного интеллекта', name: 'Анна Серова', country: 'Молдова', description: 'За исследования и разработки в области нейронных сетей, применяемых в сфере биотехнологий.' },
    { category: 'IT-волонтер года', name: 'Дмитрий Ковалев', country: 'Украина', description: 'За активное участие в создании и поддержке онлайн-платформ для дистанционного обучения в сельских школах.' }
  ]
};

const Winners = () => {
  const [year, setYear] = useState(2021);

  return (
    <div>
      <PageHeader title="Победители конкурса" subtitle="International IT Leaders Award" customBreadcrumb="Победители" />
      <div className="winners-container">
        <h2 className="winners-title">Победители International IT Leaders Award</h2>
        <div className="winners-buttons">
          {[2021, 2022, 2023, 2024].map((yr) => (
            <button key={yr} onClick={() => setYear(yr)} className={`winners-button ${year === yr ? 'active' : ''}`}>
              {yr}
            </button>
          ))}
        </div>
        <div className="winners-list">
          {winnersData[year].map((winner, index) => (
            <div key={index} className="winner-card">
              <h3 className="winner-category">{winner.category}</h3>
              <h4 className="winner-name">{winner.name}</h4>
              <p className="winner-country">{winner.country}</p>
              <p className="winner-description">{winner.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Winners;
