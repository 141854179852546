import React, { useEffect } from 'react';
import PageHeader from './PageHeader';

const Regulations = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="document-container">
      <PageHeader title="Положение" subtitle="International IT Leaders Award" customBreadcrumb="Положение" />
      <div className="document-content">
        <h2>I. Общие положения</h2>
        <p>1.1. Премия International IT Leaders Award (далее – Премия) учреждена и организуется Global Association of IT Experts (GAITE).</p>
        <p>1.2. Премия проводится ежегодно с 15 по 31 января ежегодно, начиная с 2021 года, подведение итогов 1 февраля и направлена на признание и награждение лучших IT-специалистов из стран СНГ.</p>
        <p>1.3. Участие в Премии могут принимать специалисты с более чем трехлетним опытом работы в сфере информационных технологий, доказавшие свою исключительную компетентность и достижения.</p>
        <p>1.4. Премия является престижным международным событием, которое способствует укреплению профессиональной репутации и расширению карьерных возможностей участников.</p>
        <h2>II. Цели и задачи премии</h2>
        <p>2.1. Признание и награждение выдающихся IT-специалистов за их профессиональные достижения и вклад в развитие информационных технологий.</p>
        <p>2.2. Стимулирование инновационной деятельности и развитие передовых технологий в IT-индустрии стран СНГ.</p>
        <p>2.3. Создание платформы для обмена опытом и знаниями между ведущими IT-экспертами и профессионалами.</p>
        <p>2.4. Повышение статуса и престижа профессии IT-специалиста на международном уровне.</p>
        <h2>III. Оргкомитет, Комитет по присуждению премии, Экспертный совет</h2>
        <p>3.1. Оргкомитет Премии отвечает за организацию и проведение мероприятия, включая прием заявок, координацию работы Комитета по присуждению премии и Экспертного совета.</p>
        <p>3.2. Комитет по присуждению премии (далее – Комитет) включает в себя ведущих IT-экспертов, профессионалов и представителей академического сообщества, которые принимают окончательные решения о присуждении премий.</p>
        <p>3.3. Экспертный совет состоит из авторитетных специалистов в области IT, которые проводят предварительную оценку заявок и выдвигают кандидатов на рассмотрение Комитета.</p>
        <h2>IV. Порядок выдвижения кандидатов на присуждение премии</h2>
        <p>4.1. Выдвижение кандидатов на присуждение Премии осуществляется посредством подачи заявок от организаций, компаний или индивидуальных лиц.</p>
        <p>4.2. Заявка должна содержать полную информацию о кандидате, включая описание его профессиональных достижений, проектов и вкладов в развитие IT-индустрии.</p>
        <p>4.3. Сроки подачи заявок и критерии отбора кандидатов публикуются на официальном сайте GAITE.</p>
        <h2>V. Порядок рассмотрения заявок и присуждение премии International IT Leaders Award</h2>
        <p>5.1. Все поданные заявки проходят предварительную оценку Экспертным советом на соответствие установленным критериям.</p>
        <p>5.2. Экспертный совет проводит анализ и отбор лучших заявок, которые затем направляются на рассмотрение Комитета по присуждению премии.</p>
        <p>5.3. Комитет проводит финальное обсуждение и голосование по кандидатам, определяя лауреатов Премии.</p>
        <p>5.4. Лауреаты Премии объявляются на официальной церемонии награждения, которая проводится ежегодно 1 февраля.</p>
        <p>5.5. Результаты присуждения Премии публикуются на официальном сайте GAITE и в других средствах массовой информации.</p>
      </div>
    </div>
  );
};

export default Regulations;
