import React, { useEffect } from 'react';
import PageHeader from './PageHeader';

const EvaluationCriteria = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <div className="document-container">
      <PageHeader title="Критерии оценки" subtitle="International IT Leaders Award" customBreadcrumb="Критерии оценки" />
      <div className="document-content">
        <h2>Критерии оценки номинаций для International IT Leaders Award:</h2>

        <h3>IT-специалист года</h3>
        <p>Признается специалист, продемонстрировавший выдающиеся достижения и вклад в развитие IT-индустрии в текущем году.</p>
        <ul>
          <li><strong>Профессиональные достижения:</strong> Значительные успехи в предыдущем году, подтвержденные выполненными проектами и результатами.</li>
          <li><strong>Вклад в индустрию:</strong> Влияние на развитие IT-индустрии, внедрение новых технологий или улучшение существующих процессов.</li>
          <li><strong>Инновации:</strong> Создание и внедрение инновационных решений, которые оказали положительное воздействие на отрасль.</li>
          <li><strong>Репутация и признание:</strong> Отзывы коллег, партнеров и признание профессиональным сообществом.</li>
          <li><strong>Участие в профессиональном сообществе:</strong> Активное участие в конференциях, форумах и других мероприятиях, направленных на развитие отрасли.</li>
        </ul>

        <h3>Лучший разработчик программного обеспечения</h3>
        <p>Признается за выдающиеся достижения в области разработки программного обеспечения.</p>
        <ul>
          <li><strong>Качество разработок:</strong> Высокий уровень качества и надежности созданного программного обеспечения.</li>
          <li><strong>Инновационность:</strong> Внедрение новых технологий и подходов в разработке программного обеспечения.</li>
          <li><strong>Эффективность решений:</strong> Достижение значительных результатов, улучшение производительности и функциональности продуктов.</li>
          <li><strong>Вклад в команду:</strong> Участие и лидерство в командных проектах, наставничество и развитие коллег.</li>
          <li><strong>Реализация проектов:</strong> Успешная реализация и запуск крупных и значимых проектов.</li>
        </ul>

        <h3>Лидер в области кибербезопасности</h3>
        <p>Награждается специалист, внесший значительный вклад в развитие и обеспечение кибербезопасности.</p>
        <ul>
          <li><strong>Профессиональные достижения:</strong> Успешное предотвращение кибератак, внедрение систем защиты и другие достижения в области кибербезопасности.</li>
          <li><strong>Инновационность:</strong> Разработка и внедрение инновационных решений и технологий для обеспечения кибербезопасности.</li>
          <li><strong>Вклад в индустрию:</strong> Влияние на развитие и стандартизацию кибербезопасности в отрасли.</li>
          <li><strong>Образовательная деятельность:</strong> Публикации, участие в семинарах, мастер-классах и обучение коллег и специалистов.</li>
          <li><strong>Репутация и признание:</strong> Отзывы коллег, партнеров и признание профессиональным сообществом.</li>
        </ul>

        <h3>Лидер в области искусственного интеллекта</h3>
        <p>Признается специалист, внесший значительный вклад в развитие и внедрение технологий искусственного интеллекта.</p>
        <ul>
          <li><strong>Профессиональные достижения:</strong> Успешные проекты и внедрение решений на основе искусственного интеллекта.</li>
          <li><strong>Инновационность:</strong> Внедрение новых подходов и технологий в области искусственного интеллекта.</li>
          <li><strong>Вклад в науку и исследования:</strong> Участие в научных исследованиях, публикации и разработка новых методов и алгоритмов.</li>
          <li><strong>Практическое применение:</strong> Успешные кейсы и примеры практического применения технологий искусственного интеллекта.</li>
          <li><strong>Образовательная деятельность:</strong> Обучение и развитие коллег, участие в конференциях и семинарах.</li>
        </ul>

        <h3>IT-волонтер года</h3>
        <p>Признается специалист, который внес значительный вклад в общественные и благотворительные IT-проекты.</p>
        <ul>
          <li><strong>Общественный вклад:</strong> Участие в благотворительных и общественных IT-проектах, направленных на помощь и развитие общества.</li>
          <li><strong>Инициативность:</strong> Личная инициатива и организация волонтерских проектов и мероприятий.</li>
          <li><strong>Результаты работы:</strong> Конкретные результаты и достижения, достигнутые в рамках волонтерской деятельности.</li>
          <li><strong>Социальная значимость:</strong> Влияние на улучшение жизни общества, развитие социальных программ и инициатив.</li>
          <li><strong>Репутация и признание:</strong> Отзывы и признание коллег, участников проектов и общественных организаций.</li>
        </ul>
      </div>
    </div>
  );
};

export default EvaluationCriteria;
